import Col from 'react-bootstrap/Col'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Section from '../components/layout/Section'
import { StaticImage } from 'gatsby-plugin-image'
import BaseLayout from '../components/layout/BaseLayout'
import styled from '@emotion/styled'
import theme from '../theme'
import { css } from '@emotion/react'
import makeSeoTitle from '../lib/utils/makeSeoTitle'
import CallToAction from '../components/elements/CallToAction'
import SectionHeader from '../components/elements/SectionHeader'

const StepIndicator = styled.span`
  color: ${theme.palette.blue};
  font-size: 2.2rem;
  font-weight: 700;
  margin-right: 16px;
`

const IndentedP = styled.p`
  padding-left: 2.3rem;
`

const ProjectProcedurePage = () => (
  <BaseLayout>
    <Section>
      <Row>
        <Col md={6}>
          <StaticImage
            src="../assets/img/pages/pricing/pencil.png"
            quality={100}
            placeholder="blurred"
            alt="EisDesigns Logo"
          />
        </Col>
        <Col md={6} className="vertical-center">
          <h1>Zusammenarbeit während eines Projekts</h1>
          <p>
            Als selbständiger Grafikdesigner betreue ich Sie von Anfang bis Ende
            Ihres Projektes.
          </p>

          <p>
            Ein wichtiger Bestandteil für die erfolgreiche Umsetzung Ihres
            Projektes ist eine gute Beratung im Vorfeld. Nach dieser kümmere ich
            mich um die Umsetzung des Designs, wobei Ihre Vorstellungen und
            Ziele die entscheidende Rolle spielen.
          </p>

          <p>
            Während dieses Prozesses bleibe ich mit Ihnen in ständigem Kontakt,
            um Sie über den aktuellen Stand zu informieren und weitere Ideen
            oder Vorschläge in den derzeitigen Stand des Entwurfs einfließen zu
            lassen.
          </p>
        </Col>
      </Row>
    </Section>

    <Section backgroundColor="#fff">
      <div
        css={css`
          text-align: center;
          margin-bottom: 4rem;
        `}
      >
        <SectionHeader title="Wie funktioniert es?" />
      </div>

      <Row
        css={css`
          @media only screen and (min-width: 768px) {
            margin-bottom: 3rem;
          }
        `}
      >
        <Col md={6}>
          <h2>
            <StepIndicator>1</StepIndicator>Teilen Sie mir Ihre Idee mit!
          </h2>
          <IndentedP>
            Senden Sie mir eine Nachricht oder Email und teilen Sie mir Ihre
            Ideen oder Ziele so detailliert wie möglich mit. Nach Erhalt Ihrer
            Nachricht setzen wir uns für ein Briefing zusammen, um alle Details
            zu klären.
          </IndentedP>

          <IndentedP>
            Am Ende dieses Briefings werde ich Ihnen die voraussichtlich
            benötigte Zeit sowie einen Termin als auch eine Preisspanne nennen
            können. Wenn alles besprochen ist, kann es direkt losgehen.
          </IndentedP>
        </Col>

        <Col
          md={6}
          css={css`
            @media only screen and (min-width: 768px) {
              padding-left: 3rem;
            }
          `}
        >
          <h2>
            <StepIndicator>2</StepIndicator>Gemeinsam Innovationen schaffen
          </h2>
          <IndentedP>
            Im nächsten Schritt bespreche ich mit Ihnen Ihre
            Projektspezifikationen wie beispielsweise Themen, Kunststil,
            Komponenten, Farbschemata und mehr.
          </IndentedP>

          <IndentedP>
            Auf Wunsch erhalten Sie Updates über den aktuellen Verlauf, sowie
            die Möglichkeit Änderungen und neue Ideen direkt in den Design
            Prozess einfließen zu lassen. Eine direkte Kommunikation zwischen
            Ihnen und mir ist hier sehr wichtig.
          </IndentedP>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <h2>
            <StepIndicator>3</StepIndicator>Finale Übergabe des Designs
          </h2>
          <IndentedP>
            Nachdem Sie den endgültigen Entwurf genehmigt haben, füllen Sie
            bitte mein Formular für Kundendaten aus und übermitteln mir so Ihre
            Rechnungsdaten.
          </IndentedP>

          <IndentedP>
            Ihre Rechnung sowie ein Download-Link für Ihre Dateien erhalten Sie
            per E-Mail.
          </IndentedP>

          <IndentedP>
            Mit der Vollendung Ihres Projekts erwerben Sie offiziell alle
            Nutzungsrechte an Ihrem Design.
          </IndentedP>
        </Col>

        <Col
          md={6}
          css={css`
            @media only screen and (min-width: 768px) {
              padding-left: 3rem;
            }
          `}
        >
          <h2>
            <StepIndicator>4</StepIndicator>Nach dem Projekt
          </h2>

          <IndentedP>
            Wenn Sie nach Ihrem Projekt noch Fragen haben stehe ich Ihnen gerne
            beratend zur Seite.
          </IndentedP>

          <IndentedP>
            Sollten Sie nachträglich etwas ändern oder hinzufügen wollen, biete
            ich Ihnen für schnelle und kleine Änderungen eine unverbindliche
            Beratung sowie eine kostenlose Bearbeitung in Aussicht.
          </IndentedP>

          <IndentedP>
            Sollte es sich allerdings hier um eine größere Aufgabe oder ein
            Problem handeln werde ich diese nur gegen eine Bearbeitungsgebühr
            realisieren können.
          </IndentedP>
        </Col>
      </Row>
    </Section>
    <CallToAction buttonLink="/kontakt/" buttonText="Kontakt">
      Sie sind bereit loszulegen? Dann lassen Sie mir doch eine Nachricht
      zukommen!
    </CallToAction>
  </BaseLayout>
)

export const Head = () => (
  <>
    <title>{makeSeoTitle('Projektablauf')}</title>
  </>
)

export default ProjectProcedurePage
